let baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_LIVE;

export { baseURL };

// let baseURL =
//     process.env.NODE_ENV === 'development'
//         ? process.env.REACT_APP_BASE_URL_DEV
//         : (baseURL =
//               process.env.NODE_ENV === 'production' && window.location.href.includes('http://10.0.1.4/')
//                   ? process.env.REACT_APP_BASE_URL_QA
//                   : //   : process.env.NODE_ENV === 'production' && window.location.href.includes('http://10.0.0.13/')
//                     //   ? process.env.REACT_APP_BASE_URL_LIVE
//                     process.env.REACT_APP_BASE_URL_LIVE);
// export { baseURL };
