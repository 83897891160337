import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'authenticationSlice',
    initialState: {
        loginData: '',
        token: localStorage.getItem('token'),
        menu: localStorage.getItem('menu'),
        userRole: localStorage.getItem('userRole'),
        userId: localStorage.getItem('userId')
    },
    reducers: {
        LOGIN_SUCCESS: (state, action) => {
            return {
                ...state,
                // loginData: action.payload,
                token: localStorage.getItem('token'),
                menu: localStorage.getItem('menu'),
                userRole: localStorage.getItem('userRole'),
                userId: localStorage.getItem('userId')
            };
        },
        LOGIN_ERROR: (state) => {
            return {
                ...state,
                menu: '',
                token: '',
                userRole: '',
                userId: ''
            };
        }
    }
});

export const { LOGIN_SUCCESS, LOGIN_ERROR } = slice.actions;
export default slice.reducer;
