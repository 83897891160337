import { combineReducers } from 'redux';
import authenticationSlice from './slices/authenticationSlice';
import utilitySlice from './slices/utilitySlice';
import parserSlice from './slices/parserSlice';

export default combineReducers({
    authenticationSlice,
    utilitySlice,
    parserSlice
});
