import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../service/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { InputSwitch } from 'primereact/inputswitch';
import { handlePostRequest } from '../../service/PostTemplate';
import { Dialog } from 'primereact/dialog';
import AddEditDepartment from './AddEditDepartment';

function Department({}) {
    const [departmentData, setDepartmentData] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        accountLevelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showDialogDepartment, setShowDialogDepartment] = useState(false);
    const [editable, setEditable] = useState(false);
    const [departmentRowData, setDepartmentRowData] = useState();

    const dataTableRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getDepartmentData = async () => {
        const resp = await handleGetRequest('/api/v1/department/all');
        setDepartmentData(resp?.data);
    };

    useEffect(() => {
        getDepartmentData();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Department List</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const EditDepartment = (rowData) => {
        // navigate(`/editdepartment?departmentId=${rowData?.departmentId}&departmentName=${rowData?.departmentName}&editable=true`);
        setShowDialogDepartment(true);
        setEditable(true);
        setDepartmentRowData(rowData);
    };

    const handleSwitchChange = async (rowData) => {
        const data = {};
        const res = await dispatch(handlePostRequest(data, `/api/v1/department/activeInActive/${rowData?.departmentId}`, true, true));
        if (res?.responsecode === 1) {
            getDepartmentData();
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData?.isActive === 1}
                        onChange={() => {
                            handleSwitchChange(rowData);
                        }}
                        tooltip="Active/InActive"
                        className="InputSwitch__Active"
                    />
                    <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => EditDepartment(rowData)} className="p-button-rounded p-button-primary" />
                </div>
            </>
        );
    };

    const header = renderHeader();

    const onHideDepartmentDialog = () => {
        setShowDialogDepartment(false);
        setEditable(false);
    };

    const AddDepartmentDialog = () => {
        setShowDialogDepartment(true);
        setEditable(false);
    };

    const props = {
        onHideDepartmentDialog,
        getDepartmentData,
        editable: editable,
        departmentRowData
    };

    return (
        <>
            <Dialog visible={showDialogDepartment} resizable={false} blockScroll draggable={false} header={editable ? 'Update Department' : 'Add Department'} style={{ width: '550px' }} onHide={() => setShowDialogDepartment(false)}>
                <AddEditDepartment {...props} />
            </Dialog>

            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={AddDepartmentDialog} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>

            <div className="grid mt-5">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Departments"
                            filters={filters}
                            globalFilterFields={['user.name', 'user.email', 'departmentName', 'roleName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={departmentData}
                        >
                            <Column field="departmentName" header="Department" sortable />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Department;
