import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../service/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import EditUserDialog from './EditUserDialog';
import { Dialog } from 'primereact/dialog';

function User() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [userData, setUserData] = useState([]);
    const [userRowData, setUserRowData] = useState();
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        accountLevelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showDialogUser, setShowDialogUser] = useState(false);

    const dataTableRef = useRef(null);

    const userId = localStorage.getItem('userId');

    const getUserData = async () => {
        const res = await handleGetRequest(`/api/v1/user/id/${userId}`);
        setUserData([res?.data]);
    };

    useEffect(() => {
        getUserData();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">User List</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const handledUserDialog = (rowData) => {
        setShowDialogUser(true);
        setUserRowData(rowData);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => handledUserDialog(rowData)} className="p-button-rounded p-button-primary" />
                </div>
            </>
        );
    };

    const header = renderHeader();

    const onHideUpdateUser = () => {
        setShowDialogUser(false);
    };

    return (
        <>
            <Dialog visible={showDialogUser} resizable={false} blockScroll draggable={false} header={'Update User'} style={{ width: '550px' }} onHide={() => setShowDialogUser(false)}>
                <EditUserDialog onHideUpdateUser={onHideUpdateUser} userRowData={userRowData} getUserData={getUserData} />
            </Dialog>

            <div className="grid mt-5">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
                            filters={filters}
                            globalFilterFields={['user.name', 'user.email', 'departmentName', 'roleName']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={userData}
                        >
                            <Column field="user.name" header="Name" sortable />
                            <Column field="user.email" header="Email" sortable />
                            <Column field="departmentName" header="Department" sortable />
                            <Column field="roleName" header="Role" sortable />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default User;
