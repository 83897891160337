import { login } from '../../service/Auth';
import { LOGIN_SUCCESS, LOGIN_ERROR } from '../slices/authenticationSlice';

export const loginAction = (authData) => async (dispatch) => {
    const res = await login(authData);
    if (res?.responsecode === 1) {
        localStorage.setItem('token', res?.data?.jwtToken);
        localStorage.setItem('userRole', res?.data?.roleId);
        localStorage.setItem('userId', res?.data?.user?.userId);
        // localStorage.setItem('menu', JSON.stringify(res?.data?.menu));
        dispatch(LOGIN_SUCCESS(res?.data));
        return res;
    } else {
        dispatch(LOGIN_ERROR('Error'));
        return res;
    }
};
