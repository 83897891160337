import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Route, Routes, useLocation } from 'react-router-dom';

import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';

import Dashboard from './components/Dashboard';
import Login from './pages/Login';
import PrimeReact from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
import SignUp from './pages/SignUp';
import { useSelector } from 'react-redux';
import AddEditUser from './pages/user/AddEditUser';
import User from './pages/user/User';
import Department from './pages/department/Department';
import AddEditDepartment from './pages/department/AddEditDepartment';
import AddEditQuestion from './pages/question/AddEditQuestion';
import Question from './pages/question/Question';
import IndividualReport from './pages/reports/IndividualReport';
import DepartmentReport from './pages/reports/DepartmentReport';
import Users from './pages/user/Users';
import { NotFound } from './pages/NotFound';

const App = (props) => {
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('static');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState(null);
    const copyTooltipRef = useRef();
    const location = useLocation();

    PrimeReact.ripple = true;

    require('dotenv').config();

    const auth = useSelector((state) => state?.authenticationSlice?.token);
    const userRole = useSelector((state) => state?.authenticationSlice?.userRole);

    const adminMenu = [
        {
            label: 'Dashboard',
            icon: 'pi pi-home',
            items: [{ label: 'Dashboard', icon: 'pi pi-home', to: '/' }]
        },
        {
            label: 'Users',
            icon: 'pi pi-home',
            items: [{ label: 'Users', icon: 'pi pi-user', to: '/users' }]
        },
        {
            label: 'Department',
            icon: 'pi pi-home',
            items: [{ label: 'Department', icon: 'pi pi-building', to: '/department' }]
        },
        {
            label: 'Question',
            icon: 'pi pi-home',
            items: [{ label: 'Question', icon: 'pi pi-question', to: '/question' }]
        },
        {
            label: 'Reports',
            icon: 'pi pi-home',
            items: [
                { label: 'Individual Report', icon: 'pi pi-wallet', to: '/individualreport' },
                { label: 'Department Report', icon: 'pi pi-wallet', to: '/departmentreport' }
            ]
        }
        // {
        //     label: 'Hierarchy',
        //     icon: 'pi pi-align-left',
        //     items: [
        //         {
        //             label: 'Submenu 1',
        //             icon: 'pi pi-align-left',
        //             items: [
        //                 {
        //                     label: 'Submenu 1.1',
        //                     icon: 'pi pi-align-left',
        //                     items: [
        //                         { label: 'Submenu 1.1.1', icon: 'pi pi-align-left' },
        //                         { label: 'Submenu 1.1.2', icon: 'pi pi-align-left' },
        //                         { label: 'Submenu 1.1.3', icon: 'pi pi-align-left' }
        //                     ]
        //                 },
        //                 {
        //                     label: 'Submenu 1.2',
        //                     icon: 'pi pi-align-left',
        //                     items: [
        //                         { label: 'Submenu 1.2.1', icon: 'pi pi-align-left' },
        //                         { label: 'Submenu 1.2.2', icon: 'pi pi-align-left' }
        //                     ]
        //                 }
        //             ]
        //         }
        //     ]
        // }
    ];

    const userMenu = [
        {
            label: 'Dashboard',
            icon: 'pi pi-home',
            items: [{ label: 'Dashboard', icon: 'pi pi-home', to: '/' }]
        },
        {
            label: 'User',
            icon: 'pi pi-home',
            items: [{ label: 'User', icon: 'pi pi-user', to: '/user' }]
        },
        {
            label: 'Reports',
            icon: 'pi pi-home',
            items: [{ label: 'Individual Report', icon: 'pi pi-wallet', to: '/individualreport' }]
        }
    ];

    const routes = [
        { parent: 'Dashboard', label: '' },
        { label: 'User' },
        { label: 'Users' },
        { label: 'Add User' },
        { label: 'Edit User' },
        { label: 'Department' },
        { label: 'Add Department' },
        { label: 'Edit Department' },
        { label: 'Question' },
        { label: 'Add Question' },
        { label: 'Edit Question' },
        { label: 'Individual Report' },
        { label: 'Department Report' }
    ];

    let rightMenuClick;
    let configClick;
    let menuClick;
    let searchClick = false;
    let topbarItemClick;

    useEffect(() => {
        copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
    }, [location]);

    useEffect(() => {
        setResetActiveIndex(true);
        setMenuActive(false);
    }, [menuMode]);

    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            onSearchHide();
        }

        if (!topbarItemClick) {
            setTopbarMenuActive(false);
        }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false);
                setResetActiveIndex(true);
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false);
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) {
            setRightMenuActive(false);
        }

        if (configActive && !configClick) {
            setConfigActive(false);
        }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    };

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    };

    const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    };

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true);
    };

    const onRightMenuClick = () => {
        rightMenuClick = true;
    };

    const onRightMenuActiveChange = (active) => {
        setRightMenuActive(active);
    };

    const onConfigClick = () => {
        configClick = true;
    };

    const onConfigButtonClick = (event) => {
        setConfigActive((prevState) => !prevState);
        configClick = true;
        event.preventDefault();
    };

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    };

    const onMenuButtonClick = (event) => {
        menuClick = true;

        if (isOverlay()) {
            setOverlayMenuActive((prevState) => !prevState);
        }

        if (isDesktop()) {
            setStaticMenuDesktopInactive((prevState) => !prevState);
        } else {
            setStaticMenuMobileActive((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false);
        setStaticMenuMobileActive(false);
    };

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive((prevState) => !prevState);
        hideOverlayMenu();
        event.preventDefault();
    };

    const onToggleMenu = (event) => {
        menuClick = true;

        if (overlayMenuActive) {
            setOverlayMenuActive(false);
        }

        if (sidebarActive) {
            setSidebarStatic((prevState) => !prevState);
        }

        event.preventDefault();
    };

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop());
            }, 200);
        }
    };

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    };

    const onMenuClick = () => {
        menuClick = true;
    };

    const onChangeActiveInlineMenu = (event) => {
        setActiveInlineProfile((prevState) => !prevState);
        event.preventDefault();
    };

    const onRootMenuItemClick = () => {
        setMenuActive((prevState) => !prevState);
    };

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    };

    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };

    const isSlim = () => {
        return menuMode === 'slim';
    };

    const isOverlay = () => {
        return menuMode === 'overlay';
    };

    const isDesktop = () => {
        return window.innerWidth > 991;
    };

    const onInputClick = () => {
        searchClick = true;
    };

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    };

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
            <ToastContainer />
            {!auth || auth == 'undefined' ? (
                <Routes>
                    <Route path="/" exact="true" element={<SignUp />} />
                    <Route path="/login" exact="true" element={<Login />} />
                </Routes>
            ) : (
                <div className="layout-main">
                    <AppTopbar
                        items={userRole === '1' ? adminMenu : userMenu}
                        menuMode={menuMode}
                        colorScheme={props.colorScheme}
                        menuActive={menuActive}
                        topbarMenuActive={topbarMenuActive}
                        activeInlineProfile={activeInlineProfile}
                        onTopbarItemClick={onTopbarItemClick}
                        onMenuButtonClick={onMenuButtonClick}
                        onSidebarMouseOver={onSidebarMouseOver}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onToggleMenu={onToggleMenu}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        onMenuClick={onMenuClick}
                        onMenuItemClick={onMenuItemClick}
                        onRootMenuItemClick={onRootMenuItemClick}
                        resetActiveIndex={resetActiveIndex}
                    />

                    <AppMenu
                        model={userRole === '1' ? adminMenu : userMenu}
                        onRootMenuItemClick={onRootMenuItemClick}
                        onMenuItemClick={onMenuItemClick}
                        onToggleMenu={onToggleMenu}
                        onMenuClick={onMenuClick}
                        menuMode={menuMode}
                        colorScheme={props.colorScheme}
                        menuActive={menuActive}
                        sidebarActive={sidebarActive}
                        sidebarStatic={sidebarStatic}
                        pinActive={pinActive}
                        onSidebarMouseLeave={onSidebarMouseLeave}
                        onSidebarMouseOver={onSidebarMouseOver}
                        activeInlineProfile={activeInlineProfile}
                        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
                        resetActiveIndex={resetActiveIndex}
                    />

                    <AppBreadcrumb routes={routes} onMenuButtonClick={onMenuButtonClick} menuMode={menuMode} onRightMenuButtonClick={onRightMenuButtonClick} onInputClick={onInputClick} searchActive={searchActive} breadcrumbClick={breadcrumbClick} />

                    <div className="layout-main-content">
                        <Routes>
                            {userRole === '1' && (
                                <>
                                    <Route path="/" exact="true" element={<Dashboard />} />
                                    <Route path="/users" exact="true" element={<Users />} />
                                    <Route path="/department" exact="true" element={<Department />} />
                                    <Route path="/adddepartment" exact="true" element={<AddEditDepartment type="Add" />} />
                                    <Route path="/editdepartment" exact="true" element={<AddEditDepartment type="Edit" />} />
                                    <Route path="/question" exact="true" element={<Question />} />
                                    <Route path="/addquestion" exact="true" element={<AddEditQuestion type="Add" />} />
                                    <Route path="/editquestion" exact="true" element={<AddEditQuestion type="Edit" />} />
                                    <Route path="/individualreport" exact="true" element={<IndividualReport />} />
                                    <Route path="/departmentreport" exact="true" element={<DepartmentReport />} />
                                </>
                            )}
                            {userRole === '2' && (
                                <>
                                    <Route path="/" exact="true" element={<Dashboard />} />
                                    <Route path="/user" exact="true" element={<User />} />
                                    <Route path="/individualreport" exact="true" element={<IndividualReport />} />
                                </>
                            )}
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>

                    {/* <AppFooter colorScheme={props.colorScheme} /> */}
                </div>
            )}

            <AppRightMenu rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick} onRightMenuActiveChange={onRightMenuActiveChange} />

            <AppConfig
                configActive={configActive}
                onConfigButtonClick={onConfigButtonClick}
                onConfigClick={onConfigClick}
                menuMode={menuMode}
                changeMenuMode={onMenuModeChange}
                colorScheme={props.colorScheme}
                changeColorScheme={props.onColorSchemeChange}
                theme={props.theme}
                changeTheme={props.onMenuThemeChange}
                componentTheme={props.componentTheme}
                changeComponentTheme={props.onComponentThemeChange}
                ripple={ripple}
                onRippleChange={onRippleChange}
            />
        </div>
    );
};

export default App;
