import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router';
import { handlePostRequest } from '../../service/PostTemplate';
import { handleGetRequest } from '../../service/GetTemplate';
import { handlePutRequest } from '../../service/PutTemplate';

function EditUserDialog({ onHideUpdateUser, userRowData, getUserData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const userId = localStorage.getItem('userId');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        formik.setFieldValue('name', userRowData?.user?.name);
        formik.setFieldValue('email', userRowData?.user?.email);
    }, []);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['userId'] = userRowData?.user?.userId;
            data['companyId'] = userRowData?.user?.companyId;
            data['roleId'] = userRowData?.user?.roleId;

            const res = await dispatch(handlePutRequest(data, '/api/v1/user/update', true, true));
            if (res?.responsecode === 1) {
                formik.resetForm();
                onHideUpdateUser();
                getUserData();
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Name
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="name"
                                    placeholder="Enter name"
                                    name="name"
                                    value={formik?.values?.name?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formik?.values?.email?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn mt-5 flex flex-row justify-content-center">
                        <Button label="Cancel" onClick={() => onHideUpdateUser()} className="Btn__Dark" style={{ width: '100px', marginRight: '10px' }} />
                        <Button label={'Update User'} className="Btn__Dark" style={{ width: '100px' }} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditUserDialog;
