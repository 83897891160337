import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import bgImage from '../../src/assets/images/black.webp';
import { handlePostRequest } from '../service/PostTemplate';

const SignUp = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            companyName: '',
            companyWebsite: '',
            companyIndustry: '',
            adminName: '',
            adminEmail: '',
            adminPassword: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.companyName) {
                errors.companyName = 'Company Name is required.';
            }
            if (!data.companyWebsite) {
                errors.companyWebsite = 'Company Website is required.';
            }
            if (!data.companyIndustry) {
                errors.companyIndustry = 'Company Industry is required.';
            }
            if (!data.adminName) {
                errors.adminName = 'Admin Name is required.';
            }
            if (!data.adminEmail) {
                errors.adminEmail = 'Admin Email is required.';
            }
            if (!data.adminPassword) {
                errors.adminPassword = 'Admin Password is required.';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const res = await dispatch(handlePostRequest(data, '/api/v1/auth/registerNewCompany', true, true));
            if (res?.responsecode === 1) {
                navigate('/login');
            }

            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="login-body">
            <div className="login-image">
                <img src={bgImage} alt="atlantis" />
            </div>
            <div className="signup-panel p-fluid" style={{ background: '#fff' }}>
                <form onSubmit={formik.handleSubmit} className="">
                    <div className="flex flex-column">
                        <div className="flex align-items-center mb-6 logo-container">
                            <p className="Signin__Heading">Sign Up</p>
                        </div>
                        <div className="form-container">
                            <div className="flex flex-column w-12">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-envelope"></i>
                                    <InputText
                                        id="companyName"
                                        className={classNames({ 'p-invalid': isFormFieldValid('companyName') }, 'Login__Input')}
                                        name="companyName"
                                        value={formik.values.companyName}
                                        placeholder="Enter Company Name"
                                        onChange={formik.handleChange}
                                    />
                                    {getFormErrorMessage('companyName')}
                                </span>
                            </div>
                            <div className="flex flex-column w-12 mt-2">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-key"></i>
                                    <InputText
                                        className={classNames({ 'p-invalid': isFormFieldValid('companyWebsite') }, 'Login__Input')}
                                        name="companyWebsite"
                                        placeholder="Enter Company Website"
                                        value={formik.values.companyWebsite}
                                        onChange={formik.handleChange}
                                    />
                                    {getFormErrorMessage('companyWebsite')}
                                </span>
                            </div>
                            <div className="flex flex-column w-12 mt-2">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-key"></i>
                                    <InputText
                                        className={classNames({ 'p-invalid': isFormFieldValid('companyIndustry') }, 'Login__Input')}
                                        name="companyIndustry"
                                        placeholder="Enter Company Industry"
                                        value={formik.values.companyIndustry}
                                        onChange={formik.handleChange}
                                    />
                                    {getFormErrorMessage('companyIndustry')}
                                </span>
                            </div>
                            <div className="flex flex-column w-12 mt-2"></div>
                            <div className="flex flex-column w-12 mt-2"></div>
                            <div className="flex flex-column w-12 mt-2"></div>
                            <div className="flex flex-column w-12 mt-2"></div>
                            <div className="flex flex-column w-12 mt-2">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-key"></i>
                                    <InputText className={classNames({ 'p-invalid': isFormFieldValid('adminName') }, 'Login__Input')} name="adminName" placeholder="Enter Admin Name" value={formik.values.adminName} onChange={formik.handleChange} />
                                    {getFormErrorMessage('adminName')}
                                </span>
                            </div>
                            <div className="flex flex-column w-12 mt-2">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-key"></i>
                                    <InputText
                                        className={classNames({ 'p-invalid': isFormFieldValid('adminEmail') }, 'Login__Input')}
                                        name="adminEmail"
                                        placeholder="Enter Admin Email"
                                        value={formik.values.adminEmail}
                                        onChange={formik.handleChange}
                                    />
                                    {getFormErrorMessage('adminEmail')}
                                </span>
                            </div>
                            <div className="flex flex-column w-12 mt-2">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-key"></i>
                                    <InputText
                                        className={classNames({ 'p-invalid': isFormFieldValid('adminPassword') }, 'Login__Input')}
                                        name="adminPassword"
                                        placeholder="Enter Admin Password"
                                        value={formik.values.adminPassword}
                                        onChange={formik.handleChange}
                                        type="password"
                                    />
                                    {getFormErrorMessage('adminPassword')}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-row w-4 mt-2">
                            <p className="" style={{ color: 'black', textAlign: 'start', marginRight: '10px' }}>
                                Already Have an Account ?
                            </p>
                            <Link to="/login">Login</Link>
                        </div>
                        <div className="button-container mt-4">
                            <Button type="submit" label="Sign Up" icon={loadingIcon || ''} iconPos="right" className="Login__Button" />
                        </div>
                    </div>
                </form>

                {/* <div className="login-footer flex align-items-center">
                        <div className="flex align-items-center login-footer-logo-container">
                            <img
                            src="assets/layout/images/logo-gray.png"
                            className="login-footer-logo"
                            alt="login-footer-logo"
                            />
                            <img
                            src="assets/layout/images/appname-gray.png"
                            className="login-footer-appname"
                            alt="login-footer-appname"
                            />
                        </div>
                        <span>Copyright 2021</span>
                    </div> */}
            </div>
        </div>
    );
};

export default SignUp;
