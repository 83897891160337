import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router';
import { handlePostRequest } from '../../service/PostTemplate';
import { handleGetRequest } from '../../service/GetTemplate';
import { handlePutRequest } from '../../service/PutTemplate';

const AddEditUser = ({ editable, onHideUpdateUser, userRowData, getUserData }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [departmentData, setDepartmentData] = useState([]);
    const [roleData, setRoleData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getUserById = async () => {
        const res = await handleGetRequest(`/api/v1/user/id/${userRowData?.user?.userId}`);
        const keyData = res?.data;
        if (keyData !== null && typeof keyData !== 'undefined') {
            formik.setFieldValue('name', keyData?.user?.name);
            formik.setFieldValue('email', keyData?.user?.email);
            formik.setFieldValue('questionFrequency', keyData?.user?.questionFrequency);
            formik.setFieldValue('departmentId', keyData?.user?.departmentId);
            formik.setFieldValue('roleId', keyData?.user?.roleId);
        }
    };

    const getDepartmentData = async () => {
        const res = await handleGetRequest('/api/v1/department/all');
        setDepartmentData(res?.data);
    };

    const getRoleData = async () => {
        const res = await handleGetRequest('/api/v1/lov/roles');
        setRoleData(res?.data);
    };

    useEffect(() => {
        getDepartmentData();
        getRoleData();
        if (editable === true) {
            getUserById();
        }
    }, []);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('This field is required.').max(200, 'Max length 200 characters')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: '',
            email: '',
            password: '',
            departmentId: '',
            roleId: '',
            questionFrequency: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            if (editable === false) {
                const res = await dispatch(handlePostRequest(data, '/api/v1/user/addNew', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                    onHideUpdateUser();
                    getUserData();
                }
            } else {
                data['userId'] = userRowData?.user?.userId;
                data['companyId'] = userRowData?.user?.companyId;
                const res = await dispatch(handlePutRequest(data, '/api/v1/user/update', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                    onHideUpdateUser();
                    getUserData();
                }
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledClose = (e) => {
        e.preventDefault();
        onHideUpdateUser();
    };

    return (
        <>
            <div className="">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="name"
                                    placeholder="Enter name"
                                    name="name"
                                    value={formik?.values?.name?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                    Email<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="email"
                                    placeholder="Enter email"
                                    name="email"
                                    value={formik?.values?.email?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') }, 'Label__Text')}>
                                    Password<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    type="password"
                                    maxLength={15}
                                    id="password"
                                    placeholder="Enter password"
                                    name="password"
                                    value={formik?.values?.password?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('password') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('password')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="questionFrequency" className={classNames({ 'p-error': isFormFieldValid('questionFrequency') }, 'Label__Text')}>
                                    Question Frequency<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    keyfilter={'int'}
                                    maxLength={15}
                                    id="questionFrequency"
                                    placeholder="Enter Question Frequency"
                                    name="questionFrequency"
                                    value={formik?.values?.questionFrequency || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('questionFrequency') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('questionFrequency')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="departmentId" className={classNames({ 'p-error': isFormFieldValid('departmentId') }, 'Label__Text')}>
                                    Department<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="departmentId"
                                    placeholder="Select Department"
                                    options={departmentData}
                                    optionLabel="departmentName"
                                    name="departmentId"
                                    optionValue="departmentId"
                                    value={formik.values.departmentId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('departmentId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('departmentId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="roleId" className={classNames({ 'p-error': isFormFieldValid('roleId') }, 'Label__Text')}>
                                    Role<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="roleId"
                                    placeholder="Select Role"
                                    options={roleData}
                                    optionLabel="description"
                                    name="roleId"
                                    optionValue="id"
                                    value={formik.values.roleId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('roleId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('roleId')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn mt-5 flex flex-row justify-content-center">
                        <Button label="Cancel" onClick={(e) => handledClose(e)} className="Btn__Dark" style={{ width: '100px', marginRight: '10px' }} />
                        <Button label={editable === false ? 'Register' : 'Update User'} className="Btn__Dark" style={{ width: '100px' }} />
                    </div>
                </form>
            </div>
        </>
    );
};

export default AddEditUser;
