import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../service/PostTemplate';
import { handlePutRequest } from '../../service/PutTemplate';

function AddEditDepartment({ departmentRowData, editable, onHideDepartmentDialog, getDepartmentData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const dispatch = useDispatch();

    useEffect(() => {
        formik.setFieldValue('departmentName', departmentRowData?.departmentName);
    }, []);

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]);

    const formik = useFormik({
        initialValues: {
            departmentName: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.departmentName) {
                errors.departmentName = 'Department Name is required.';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            if (editable === false) {
                const res = await dispatch(handlePostRequest(data, '/api/v1/department/addNew', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                    onHideDepartmentDialog();
                    getDepartmentData();
                }
            } else {
                data['departmentId'] = departmentRowData?.departmentId;
                const res = await dispatch(handlePutRequest(data, '/api/v1/department/update', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                    onHideDepartmentDialog();
                    getDepartmentData();
                }
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const HandledCancelDialog = (e) => {
        e.preventDefault();
        onHideDepartmentDialog();
    };

    return (
        <>
            <div className="">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="departmentName" className={'Label__Text'}>
                                    Department Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="departmentName"
                                    placeholder="Enter Department Name"
                                    name="departmentName"
                                    value={formik?.values?.departmentName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames('Input__Round')}
                                />
                                <small className="p-error">{formik.errors.departmentName}</small>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn mt-5 flex flex-row justify-content-center">
                        <Button label="Cancel" onClick={HandledCancelDialog} className="Btn__Dark" style={{ width: '100px', marginRight: '10px' }} />
                        <Button label={editable === false ? 'Register' : 'Update'} className="Btn__Dark" style={{ width: '100px' }} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddEditDepartment;
