import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { MultiSelect } from 'primereact/multiselect';
import { useNavigate, useLocation } from 'react-router';
import { handlePostRequest } from '../../service/PostTemplate';
import { handleGetRequest } from '../../service/GetTemplate';
import { handlePutRequest } from '../../service/PutTemplate';
import { Checkbox } from 'primereact/checkbox';

function AddEditQuestion({ questionRowData, editable, onHideQuestionDialog, getQuestionData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [departmentData, setDepartmentData] = useState([]);
    const [questionDataById, setQuestionDataById] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getQuestionById = async () => {
        const res = await handleGetRequest(`/api/v1/question/id/${questionRowData?.question?.questionId}`);
        const keyData = res?.data;
        if (keyData !== null && typeof keyData !== 'undefined') {
            const OptionListData = keyData?.optionList?.map((value) => {
                return {
                    option: value?.option,
                    trueFalse: value?.trueFalse === 'true' ? true : false,
                    questionId: value?.questionId,
                    questionOptionId: value?.questionOptionId,
                    timestamp: null
                };
            });

            formik.setFieldValue('question', keyData?.question?.question);
            formik.setFieldValue('userManualLink', keyData?.question?.userManualLink);
            formik.setFieldValue('questionOptions', OptionListData);
            formik.setFieldValue(
                'departmentIdList',
                keyData?.questionDepartmentNameAndIdList?.map((value) => value?.departmentId)
            );
            setQuestionDataById(keyData);
        }
    };

    const getDepartmentData = async () => {
        const res = await handleGetRequest('/api/v1/department/all');
        setDepartmentData(res?.data);
    };

    useEffect(() => {
        getDepartmentData();

        if (editable) {
            getQuestionById();
        }
    }, []);

    const validationSchema = Yup.object().shape({
        question: Yup.string().required('This field is required.').max(200, 'Max length 200 characters')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            question: '',
            questionOptions: [
                {
                    option: '',
                    trueFalse: false
                }
            ],
            userManualLink: '',
            departmentIdList: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            // Update trueFalse based on a condition
            data.questionOptions.forEach((option) => {
                option.trueFalse = option.trueFalse ? 'true' : 'false';
            });

            // data?.questionOptions[].trueFalse ? "true" : "false";

            if (editable === false) {
                const res = await dispatch(handlePostRequest(data, '/api/v1/question/addNew', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                    onHideQuestionDialog();
                    getQuestionData();
                }
            } else {
                const newdata = {
                    question: {
                        question: data.question,
                        questionId: questionRowData?.question?.questionId,
                        userManualLink: data?.userManualLink
                    },
                    questionDepartmentsList: data?.departmentIdList,
                    optionList: data?.questionOptions
                };

                const res = await dispatch(handlePutRequest(newdata, '/api/v1/question/update', true, true));
                if (res?.responsecode === 1) {
                    formik.resetForm();
                    onHideQuestionDialog();
                    getQuestionData();
                }
            }

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const AddOption = () => {
        formik.setFieldValue('questionOptions', [
            ...formik.values.questionOptions,
            {
                option: '',
                trueFalse: false
            }
        ]);
    };

    const handledCloseDialog = (e) => {
        e.preventDefault();
        onHideQuestionDialog();
    };

    return (
        <>
            <div className=" ">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="question" className={classNames({ 'p-error': isFormFieldValid('question') }, 'Label__Text')}>
                                    Question<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="question"
                                    placeholder="Enter Question"
                                    name="question"
                                    value={formik?.values?.question}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('question') }, 'Input__Round')}
                                />
                            </div>
                            {getFormErrorMessage('question')}
                        </div>

                        {formik?.values?.questionOptions?.map((questionData, index) => {
                            return (
                                <div key={`id-${index}`} className="col-12">
                                    <div className="p-fluid p-formgrid grid">
                                        <div className="p-field col-12 md:col-10">
                                            <div className="p-field">
                                                <label htmlFor={`option${index}`} className={classNames({ 'p-error': isFormFieldValid(`questionOptions[${index}].option`) }, 'Label__Text')}>
                                                    Option
                                                    {/* <span className="Label__Required">*</span> */}
                                                </label>
                                                <InputText
                                                    id={`option${index}`}
                                                    placeholder="Enter Option"
                                                    name={`questionOptions[${index}].option`}
                                                    value={questionData.option || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid(`questionOptions[${index}].option`) }, 'Input__Round')}
                                                />
                                                <small className="p-error">{formik.touched.questionOptions?.[index]?.option && formik.errors.questionOptions?.[index]?.option}</small>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-2 mt-4">
                                            <div className="flex flex-row">
                                                <div className="flex align-items-center">
                                                    <div className="flex align-items-center mr-3">
                                                        <Checkbox inputId={`trueFalse${index}_n`} name={`questionOptions[${index}].trueFalse`} value={questionData.trueFalse} onChange={formik.handleChange} checked={questionData.trueFalse} />
                                                    </div>
                                                    {getFormErrorMessage('trueFalse')}
                                                </div>
                                                <Button
                                                    icon="pi pi-plus"
                                                    className="p-button-rounded"
                                                    aria-label="Filter"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        AddOption();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="userManualLink" className={classNames({ 'p-error': isFormFieldValid('userManualLink') }, 'Label__Text')}>
                                    User Manual Link
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="userManualLink"
                                    placeholder="Enter User Manual Link"
                                    name="userManualLink"
                                    value={formik?.values?.userManualLink}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('userManualLink') }, 'Input__Round')}
                                />
                            </div>
                            <div className="Question__Validation__MSG">{getFormErrorMessage('userManualLink')}</div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="departmentIdList" className={classNames({ 'p-error': isFormFieldValid('departmentIdList') }, 'Label__Text')}>
                                    Department
                                </label>
                                <MultiSelect
                                    id="departmentIdList"
                                    placeholder="Select Department"
                                    options={departmentData}
                                    optionLabel="departmentName"
                                    name="departmentIdList"
                                    optionValue="departmentId"
                                    value={formik.values.departmentIdList || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('departmentIdList') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('departmentIdList')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn mt-5 flex flex-row justify-content-center">
                        <Button label="Cancel" onClick={handledCloseDialog} className="Btn__Dark" style={{ width: '100px', marginRight: '10px' }} />
                        <Button label={editable === false ? 'Add' : 'Update'} className="Btn__Dark" style={{ width: '100px' }} />
                    </div>
                </form>
            </div>
        </>
    );
}

export default AddEditQuestion;
