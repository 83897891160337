import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../service/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';

function DepartmentReport() {
    const [individualreportData, setIndivindividualreportData] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        accountLevelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [departmentValue, setDepartmentValue] = useState();
    const [departmentData, setDepartmentData] = useState([]);

    const dataTableRef = useRef(null);

    const getDepartmentData = async () => {
        const resp = await handleGetRequest('/api/v1/department/all');
        setDepartmentData(resp?.data);
    };

    useEffect(() => {
        getDepartmentData();
    }, []);

    const getIndivindividualreportData = async () => {
        const resp = await handleGetRequest(`/api/v1/report/dprtPReport/${departmentValue}`);
        setIndivindividualreportData(resp?.data?.questionListTableReporting);
        setReportData(resp?.data);
    };

    useEffect(() => {
        if (departmentValue !== undefined) {
            getIndivindividualreportData();
        }
    }, [departmentValue]);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Individual Report List</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = renderHeader();

    const trueFalseBodyTemplate = (rowData) => {
        const statusColor = rowData?.trueFalse === 'correct' ? '#14A38B' : rowData?.trueFalse === 'wrong' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: '#fff', backgroundColor: statusColor, width: '70%', textAlign: 'center', borderRadius: '5px' }}>
                <b>{rowData?.trueFalse}</b>
            </p>
        );
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field flex col-12 md:col-8">
                        <label htmlFor="userId" className={classNames('Label__Text')}>
                            Select Department For Report
                        </label>
                        <Dropdown
                            id="departmentId"
                            placeholder="Select Department"
                            options={departmentData}
                            optionLabel="departmentName"
                            name="departmentId"
                            optionValue="departmentId"
                            value={departmentValue || ''}
                            onChange={(e) => setDepartmentValue(e.target.value)}
                            className={classNames('Dropdown__Round')}
                        />
                    </div>
                </div>
            </div>

            <div className="layout-dashboard">
                <div className="grid">
                    <div className="col-12 md:col-2">
                        <div className="card widget-overview-box widget-overview-box-1">
                            <span className="overview-title">Total Questions Sent</span>
                            <div className="flex justify-content-between">
                                <div className="overview-detail flex justify-content-between">
                                    <div className="overview-badge flex justify-content-center align-items-center">
                                        <span>{reportData?.totalSentQuestions}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-2">
                        <div className="card widget-overview-box widget-overview-box-1">
                            <span className="overview-title">Total Questions Answered</span>
                            <div className="flex justify-content-between">
                                <div className="overview-detail flex justify-content-between">
                                    <div className="overview-badge flex justify-content-center align-items-center">
                                        <span>{reportData?.totalAttemptedQuestions}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 md:col-2">
                        <div className="card widget-overview-box widget-overview-box-1">
                            <span className="overview-title">Correct Percentage</span>
                            <div className="flex justify-content-between">
                                <div className="overview-detail flex justify-content-between">
                                    <div className="overview-badge flex justify-content-center align-items-center">
                                        <span>{reportData?.correctPercentage?.toFixed(2)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid mt-5">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Questions"
                            filters={filters}
                            globalFilterFields={['question', 'timestamp', 'trueFalse']}
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={individualreportData}
                        >
                            <Column field="question" header="Question" sortable />
                            <Column field="question_sent_timestamp" header="Sent Time" sortable />
                            <Column field="question_solved_timestamp" header="Solved Time" sortable />
                            <Column field="trueFalse" header="True False" body={trueFalseBodyTemplate} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default DepartmentReport;
