import { baseURL } from '../Config';
import axios from 'axios';
import { toast } from 'react-toastify';

export const login = async (data) => {
    // const id = toast.loading('Please wait...');
    try {
        const response = await axios({
            method: 'post',
            url: `${baseURL}/api/v1/auth/authenticateUser`,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.update(id, { render: response.data.messages, type: 'success', isLoading: false, autoClose: 3000 });
        toast.success(response.data.messages, { autoClose: 3000 });

        return response.data;
    } catch (error) {
        if (error.response.status === 500) {
            setTimeout(() => {
                toast.update({ render: error.response.data.message || error.response.data.messages || 'Something went wrong !!', type: 'error', isLoading: false, autoClose: 3000 });
            }, 100);
        } else if (error.response.status === 400) {
            setTimeout(() => {
                toast.update({ render: error?.response?.data?.message || error.response.data.messages || 'Something went wrong !!', type: 'error', isLoading: false, autoClose: 3000 });
            }, 100);
        } else {
            setTimeout(() => {
                toast.update({ render: error.response.data.message || error.response.data.messages || 'Something went wrong !!', type: 'warn', isLoading: false, autoClose: 3000 });
            }, 100);
        }

        return error?.response;
    }
};

export const refreshToken = async (data) => {
    let res;
    await axios({
        method: 'get',
        url: `${baseURL}refreshToken`,
        data: data,
        headers: {
            Authorization: localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    })
        .then((response) => {
            localStorage.setItem('token', response.data.data);
        })
        .catch((err) => {
            res = false;
            console.err(err);
        });
    return res;
};

export const updatePassword = async (password) => {
    let res;
    await axios({
        method: 'post',
        url: `${baseURL}userManagement/changePassword`,
        data: { password },
        headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('token')
        }
    })
        .then((response) => {
            toast.success(response.data.data);
            res = response?.data?.data;
        })
        .catch((err) => {
            toast.warn(err?.response?.data?.messages || 'Something went wrong');
            res = false;
        });
    return res;
};
