import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handleGetRequest } from '../../service/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FilterMatchMode } from 'primereact/api';
import { InputSwitch } from 'primereact/inputswitch';
import { handlePostRequest } from '../../service/PostTemplate';
import { Dialog } from 'primereact/dialog';
import AddEditQuestion from './AddEditQuestion';

function Question({}) {
    const [questionData, setQuestionData] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        accountLevelName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [expandedRows, setExpandedRows] = useState(null);
    const [optionsData, setoptionsData] = useState([]);
    const [showDialogQuestion, setShowDialogQuestion] = useState(false);
    const [editable, setEditable] = useState(false);
    const [questionRowData, setQuestionRowData] = useState();
    const [departmentNameRowData, setDepartmentNameRowData] = useState([]);

    // const dataTableRef = useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getQuestionData = async () => {
        const resp = await handleGetRequest('/api/v1/question/all');
        setQuestionData(resp?.data);
    };

    useEffect(() => {
        getQuestionData();
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Question List</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const EditDepartment = (rowData) => {
        // navigate(`/editquestion?questionId=${rowData?.question?.questionId}&editable=true`);
        setShowDialogQuestion(true);
        setEditable(true);
        setQuestionRowData(rowData);
    };

    const handleSwitchChange = async (rowData) => {
        const data = {};
        const res = await dispatch(handlePostRequest(data, `/api/v1/question/activeInActive/${rowData?.companyQuestion?.questionId}`, true, true));
        if (res?.responsecode === 1) {
            getQuestionData();
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData?.question?.isActive === 1}
                        onChange={() => {
                            handleSwitchChange(rowData);
                        }}
                        tooltip="Active/InActive"
                        className="InputSwitch__Active"
                    />
                    <Button tooltip="Edit" icon="pi pi-pencil" tooltipOptions={{ position: 'top' }} onClick={() => EditDepartment(rowData)} className="p-button-rounded p-button-primary" />
                </div>
            </>
        );
    };

    const header = renderHeader();

    const rowExpansionTemplate = (questionData) => {
        setoptionsData(questionData?.optionList);

        return (
            <div className="orders-subtable">
                <DataTable
                    value={optionsData}
                    paginator
                    rows={10}
                    // rowsPerPageOptions={[5, 10, 25]}
                    className="datatable-responsive"
                    emptyMessage="No data found."
                    // header="Discount Configuration Slab"
                    responsiveLayout="scroll"
                >
                    <Column field="option" header="Question Option" headerStyle={{ width: '20%', minWidth: '10rem' }} />
                    <Column field="trueFalse" header="True False" headerStyle={{ width: '20%', minWidth: '10rem' }} />
                </DataTable>
            </div>
        );
    };

    const DepartmentNameBodyTemplate = (rowData) => {
        return (
            <>
                {rowData?.questionDepartmentNameAndIdList?.map((value) => (
                    <div key={value.departmentId}>{value.departmentName}</div>
                ))}
            </>
        );
    };

    const onHideQuestionDialog = () => {
        setShowDialogQuestion(false);
        setEditable(false);
    };

    const AddQuestionDialogHandled = () => {
        setShowDialogQuestion(true);
        setEditable(false);
    };

    return (
        <>
            <Dialog visible={showDialogQuestion} resizable={false} blockScroll draggable={false} header={editable ? 'Update Question' : 'Add Question'} style={{ width: '550px' }} onHide={() => setShowDialogQuestion(false)}>
                <AddEditQuestion onHideQuestionDialog={onHideQuestionDialog} getQuestionData={getQuestionData} editable={editable} questionRowData={questionRowData} />
            </Dialog>

            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={AddQuestionDialogHandled} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>

            <div className="grid mt-5">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            paginator
                            rows={10}
                            rowsPerPageOptions={[10, 15, 20]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Questions"
                            emptyMessage="No List found."
                            header={header}
                            responsiveLayout="scroll"
                            value={questionData}
                            expandedRows={expandedRows}
                            onRowToggle={(e) => {
                                setExpandedRows(e.data);
                            }}
                            rowExpansionTemplate={rowExpansionTemplate}
                        >
                            <Column expander style={{ width: '3em' }} />
                            <Column field="question.question" header="Question" sortable />
                            <Column body={DepartmentNameBodyTemplate} header="Department" />
                            <Column field="question.userManualLink" header="User Manual Link" sortable />
                            <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Question;
