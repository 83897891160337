import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import bgImage from '../../src/assets/images/black.webp';
import bgImageSignIn from '../../src/assets/images/aba-big-logo.png';
import { loginAction } from '../redux/actions/authAction';

const Login = () => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [checked, setChecked] = useState();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const handleUrl = () => {
        navigate('/forgetpassword');
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};

            if (!data.email) {
                errors.email = 'Email is required.';
            }
            if (!data.password) {
                errors.password = 'Password is required.';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const res = await dispatch(loginAction(data));
            setloading(false);
            setloadingIcon('pi pi-save');
            if (res?.responsecode === 1) {
                navigate('/');
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <div className="login-body">
            <div className="login-image">
                <img src={bgImage} alt="atlantis" />
            </div>
            <div className="login-panel p-fluid" style={{ background: '#fff' }}>
                <form onSubmit={formik.handleSubmit} className="">
                    <div className="flex flex-column">
                        <div className="flex align-items-center mb-6 SignIn__ImageLogo">
                            {/* <p className="Signin__Heading">Sign in</p> */}
                            <img src={bgImageSignIn} alt="Sign In" />
                        </div>
                        <div className="form-container">
                            <div className="flex flex-column w-12">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-envelope"></i>
                                    <InputText
                                        type="email"
                                        id="email"
                                        className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Login__Input')}
                                        name="email"
                                        value={formik.values.email}
                                        placeholder="Enter Email"
                                        onChange={formik.handleChange}
                                    />
                                    {getFormErrorMessage('email')}
                                </span>
                            </div>
                            <div className="flex flex-column w-12 mt-2">
                                <span className="p-input-icon-left">
                                    <i className="pi pi-key"></i>
                                    <InputText
                                        className={classNames({ 'p-invalid': isFormFieldValid('password') }, 'Login__Input')}
                                        name="password"
                                        placeholder="Enter Password"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        type="password"
                                    />
                                    {getFormErrorMessage('password')}
                                </span>
                            </div>
                            <div className="flex flex-row w-4 mt-2">
                                <p className="" style={{ color: 'black', textAlign: 'start', marginRight: '10px' }}>
                                    Create a new account ?
                                </p>
                                <Link to="/">Sign Up</Link>
                            </div>
                            {/* <div className="Remember__Forget flex justify-content-between flex-wrap">
                                <div className="field-checkbox mt-1">
                                    <Checkbox inputId="binary" checked={checked} onChange={(e) => setChecked(e.checked)} className="checkbox_login" />
                                    <label style={{ cursor: 'pointer' }} htmlFor="binary" className="Remember_label">
                                        Remember Me
                                    </label>
                                </div>
                                <div className="mt-1">
                                    <label style={{ cursor: 'pointer' }} onClick={handleUrl} htmlFor="" className="forgot_label">
                                        Forgot Password
                                    </label>
                                </div>
                            </div> */}
                        </div>
                        <div className="button-container mt-4">
                            <Button type="submit" label="Login" icon={loadingIcon || ''} iconPos="right" className="Login__Button" />
                        </div>
                    </div>
                </form>

                {/* <div className="login-footer flex align-items-center">
          <div className="flex align-items-center login-footer-logo-container">
            <img
              src="assets/layout/images/logo-gray.png"
              className="login-footer-logo"
              alt="login-footer-logo"
            />
            <img
              src="assets/layout/images/appname-gray.png"
              className="login-footer-appname"
              alt="login-footer-appname"
            />
          </div>
          <span>Copyright 2021</span>
        </div> */}
            </div>
        </div>
    );
};

export default Login;
